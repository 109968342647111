import { runInAction } from "mobx";
import root from "../../";
import {
    addCmdComment,
    createCmd,
    getAdrsPlaceDetails,
    getCmd,
    getOneCmdComments,
    patchCmdAdrs,
    patchCmdBase,
    patchCmdTools,
} from "../../../modules/api-service";
import { Cmd } from "../../../modules/cls/cmd";
import { doneTask, startTask } from "../../work-progress/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import { get } from "lodash";

export const requireCmdById = (id) => {
    let _cmd = root.cmds[id];
    if (!_cmd) {
        _cmd = new Cmd(id);
        root.cmds[id] = _cmd;
    }

    return _cmd;
};

export const mkAdrsPatchData = (
    inpTyp,
    adrs,
    street,
    nr,
    zip,
    city,
    lat,
    lng
) => {
    return {
        adrs,
        lat,
        lng,
        adrs_nr: nr,
        adrs_street: street,
        adrs_city: city,
        adrs_zip: zip,
        adrs_inp_type: inpTyp,
    };
};

export const actPatchCmdAdrs = async (cmdId, data) => {
    let _wpId;

    try {
        _wpId = startTask(`actPatchCmdAdrs_${cmdId}`);

        await patchCmdAdrs(cmdId, data);

        const _cmd = requireCmdById(cmdId);
        _cmd.fromAdrsPatch(data);
    } catch (err) {
        addError({
            lbl: "save_cmd_adrs",
        });
        BugCrash.notifyExt("actPatchCmdAdrs", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actSavePlaceIdAsCmdAdrs = async (cmdId, placeId) => {
    let _wpId;

    try {
        _wpId = startTask(`actSavePlaceIdAsCmdAdrs_${cmdId}_${placeId}`);

        const _adrsInfo = await getAdrsPlaceDetails(placeId);
        if (!_adrsInfo?.ok) {
            console.warn(
                "Google place-details API answer not OK!",
                placeId,
                _adrsInfo
            );
            throw new Error(
                `Could not get the details of place ${placeId} via Google place-details API!`
            );
        }

        //console.log("_adrsInfo", cmdId, placeId, _adrsInfo);
        const _patchData = mkAdrsPatchData(
            "ADRS",
            _adrsInfo.fmt,
            _adrsInfo.adrs?.street,
            _adrsInfo.adrs?.nr,
            _adrsInfo.adrs?.zip,
            _adrsInfo.adrs?.city,
            _adrsInfo.lat,
            _adrsInfo.lng
        );

        await patchCmdAdrs(cmdId, _patchData);

        const _cmd = requireCmdById(cmdId);
        _cmd.fromAdrsPatch(_patchData);

        return _patchData;
    } catch (err) {
        addError({
            lbl: "save_cmd_adrs_place_id",
        });
        BugCrash.notifyExt("actSavePlaceIdAsCmdAdrs", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actLoadCmd = async (cmdId) => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadCmd_${cmdId}`);

        const _cmd = requireCmdById(cmdId);
        _cmd.setFullLoadStarted();

        const _srvResp = await getCmd(cmdId);

        runInAction(() => {
            _cmd.fromSrv(_srvResp);
        });
    } catch (err) {
        console.error(err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actPatchCmdBase = async (cmdId, patchData) => {
    let _wpId;

    try {
        _wpId = startTask(`actPatchCmdBase_${cmdId}`);

        await patchCmdBase(cmdId, patchData);
        await actLoadCmd(cmdId);
    } catch (err) {
        addError({
            lbl: "save_cmd_base",
        });
        BugCrash.notifyExt("actPatchCmdBase", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actPatchCmdTools = async (cmdId, tools) => {
    let _wpId;

    try {
        _wpId = startTask(`actPatchCmdTools_${cmdId}`);

        await patchCmdTools(cmdId, tools);
        await actLoadCmd(cmdId);

        return true; // No exceptions were thrown, so return true
    } catch (err) {
        addError({
            lbl: "save_cmd_tools",
        });
        BugCrash.notifyExt("actPatchCmdTools", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actCreateCmd = async (cmdId, sm, client, region, eingang) => {
    let _wpId;

    try {
        _wpId = startTask(`actCreateCmd_${cmdId}`);

        await createCmd(cmdId, { sm, client, region, eingang });
        await actLoadCmd(cmdId);
    } catch (err) {
        addError({
            lbl: "save_new_cmd",
        });
        BugCrash.notifyExt("actCreateCmd", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actLoadOneCmdComments = async (cmdId) => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadOneCmdComments_${cmdId}`);

        const _srvResp = await getOneCmdComments(cmdId);
        const _cmd = requireCmdById(cmdId);

        runInAction(() => {
            for (const _srvItm of get(_srvResp, ["itms"], [])) {
                _cmd.upsertComment(_srvItm);
            }
        });
    } catch (err) {
        addError({
            lbl: "load_cmd_comments",
        });
        BugCrash.notifyExt("actLoadOneCmdComments", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actNewCmdComment = async (cmdId, comentariu) => {
    let _wpId;

    try {
        _wpId = startTask(`actNewCmdComment_${cmdId}`);

        await addCmdComment(cmdId, comentariu);
        await actLoadOneCmdComments(cmdId);
    } catch (err) {
        addError({
            lbl: "save_new_cmd_comment",
        });
        BugCrash.notifyExt("actNewCmdComment", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
